import { render, staticRenderFns } from "./costsList.vue?vue&type=template&id=08ae885c&scoped=true&"
import script from "./costsList.vue?vue&type=script&lang=js&"
export * from "./costsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08ae885c",
  null
  
)

export default component.exports