<template>
  <div class="costs-list-page fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="costsList"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getCosts()"/>


    <button id="printTableCost" v-show="false" @click="handlePrintCosts"></button>
  </div>
</template>

<script>
import {getDocuments, getCostPrice} from '@/http/requests/documents'
import axios from 'axios'
import {addComma, getTimeFromServer, hideLoading, showLoading} from '../../../../../assets/js/functions'
import {getCostTypes, printCosts} from '../../../../../http/requests/documents'
import {getBanks} from '../../../../../http/requests/banks'
import {getCashBoxes} from '../../../../../http/requests/cashBoxes'

export default {
  name: 'costsList',
  metaInfo () {
    return {
      title: this.$t('treasury.cost.list.title')
    }
  },
  data () {
    return {
      loadingTimer: 0,
      options: {
        id: 'costsList',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'price',
          i18n: 'treasury.cost.table.header.price',
          width: 'calc(100% / 9)',
          minWidth: 120,
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                  'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
          footer: {}
        },
        {
          field: 'destination',
          i18n: 'treasury.cost.table.header.account',
          width: 'calc(100% / 9 * 2)',
          minWidth: 200,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('treasury.cost.labels.all'),
              value: ''
            }
          ]
        },
        {
          field: 'payer',
          i18n: 'treasury.cost.table.header.payer',
          width: 'calc(100% / 9 * 2)',
          minWidth: 200,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterType: 'select',
          filterTypes: [{
            label: this.$t('treasury.cost.labels.all'),
            value: 0
          }]
        },
        {
          field: 'creator',
          i18n: 'treasury.cost.table.header.creator',
          width: 'calc(100% / 9 * 2)',
          minWidth: 200,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'date',
          i18n: 'treasury.cost.table.header.date',
          width: 'calc(100% / 9)',
          minWidth: 130,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterType: 'date',
          filterRange: true
        },
        {
          field: 'id',
          i18n: 'treasury.cost.table.header.docNumber',
          width: 'calc(100% / 9)',
          minWidth: 80,
          locked: true,
          align: 'center',
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                  'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
          footer: {
            type: 'auto-counter'
          }
        }
      ],
      sourceTypesMapper: {
        'User': this.$t('treasury.cost.types.user'),
        'Bank': this.$t('treasury.cost.types.bank'),
        'Cash': this.$t('treasury.cost.types.cash'),
        'Storeroom': this.$t('treasury.cost.types.storeroom'),
        'costGateway': this.$t('treasury.cost.types.paymentGateway')
      },
      data: [],
      filters: [],
      sorts: ['order[0]=date,desc'],
      page: 1,
      endedList: false,
      requestSent: false,
      priceRequestSent: false,
      actions: [
        {
          toolbar: [
            {
              id: {name: 'insertTreasuryCost'},
              type: 'link',
              icon: 'icon-plus',
              iconPack: 'feather',
              color: 'success',
              permission: ['cost.create']
            }
          ],
          leftToolbar: [
            // {
            //   id: 'printTableCost',
            //   icon: 'icon-printer',
            //   iconPack: 'feather'
            // },
            {
              id: 'downloadTable',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)

    this.getBanksFilters()
    this.getCashBoxFilters()
    this.getCostTypesFilters()
    this.getCosts()
  },
  methods: {
    getCosts () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.costsList && this.data.length === 0) this.$refs.costsList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.costsList && this.data.length > 0) this.$refs.costsList.loadMoreStatus = 'Loading'

            getDocuments('cost', this.page, this.filters, this.sorts).then(response => {
              this.endedList = response.data.data.length === 0
              const documents = response.data.data
              // let banks = this.banks
              documents.forEach((document) => {
                this.data.push({
                  route: {
                    name: 'treasuryCostDocument',
                    params: {id: document.id}
                  },
                  id: document.document_id,
                  creator: document.creator.name,
                  description: document.description,
                  payer: document.payer.name || '',
                  destination: document.account.name || '',
                  price: {
                    type: 'price',
                    value: document.final_price || document.price
                  },
                  date: document.date
                })
              })

              this.page = response.data.pagination.current_page + 1
              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('id')
                this.columnsLabel[row_index].footer.value = response.data.pagination.total
              }

              if (this.$refs.costsList) this.$refs.costsList.loadMoreStatus = ''
              this.requestSent = false
            }).catch((error) => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              }
              this.requestSent = false

              /*if (this.$refs.costsList)
              this.$refs.costsList.loadMoreStatus = 'Danger'*/
            })
          } else {
            this.requestSent = false
          }
        }, 400)
      }
    },
    getCostPrice () {
      if (!this.priceRequestSent) {
        this.priceRequestSent = true
        getCostPrice(this.filters).then(response => {
          this.priceRequestSent = false
          const price_index = this.columnsLabel.map((e) => {
            return e.field
          }).indexOf('price')
          this.columnsLabel[price_index].footer = {
            value: addComma(response.data.data.total)
          }
        })
          .catch(() => {
            this.priceRequestSent = false
          })
      }
    },
    setFilters (filters) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

          case 'id':
            if (filters[key].search !== '') filters_list.push(`document=${  filters[key].search},${  filters[key].type.id}`)
            break

          case 'creator':
            if (filters[key].search !== '') filters_list.push(`creator=${  filters[key].search}`)
            break

          case 'description':
            if (filters[key].search !== '') filters_list.push(`description=${  filters[key].search}`)
            break

          case 'price':
            if (filters[key].search !== '') filters_list.push(`final_price=${  filters[key].search},${  filters[key].type.id}`)
            break

          case 'payer':
            if (filters[key].search.value.length > 0) filters_list.push(`payer=${  filters[key].search.value }`)
            break

          case 'destination':
            if (filters[key].search.value > 0) filters_list.push(`account=${  filters[key].search.value }`)
            break

          case 'date':
            if (filters[key].search.length > 0) filters_list.push(`date=${  filters[key].search.join('_')}`)
            break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getCosts()
      this.getCostPrice()
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

          case 'id':
            sorts_list.push(`order[0]=document_id,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'creator':
            sorts_list.push(`order[0]=creator,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'description':
            sorts_list.push(`order[0]=description,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'price':
            sorts_list.push(`order[0]=final_price,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'payer':
            sorts_list.push(`order[0]=payer_id,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'destination':
            sorts_list.push(`order[0]=account,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'date':
            sorts_list.push(`order[0]=date,${  columns[key] ? 'desc' : 'asc'}`)
            break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getCosts()
    },
    handlePrintCosts () {
      showLoading()

      printCosts(this.filters, this.sorts).then(response => {
        hideLoading()

        const data = response.data

        const time = getTimeFromServer()
        const fileURL = window.URL.createObjectURL(new Blob([data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `costList-${time}.pdf`)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
        .catch((error) => {
          hideLoading()

          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('treasury.cost.notifications.print.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
    },
    getBanksFilters () {
      getBanks().then(response => {
        const banks = response.data.data
        const payerIndex = this.columnsLabel.map(e => e.field).indexOf('payer')
        banks.forEach((bank) => {
          if (payerIndex > -1) {
            this.columnsLabel[payerIndex].filterTypes.push({
              label: bank.name,
              value: bank.name
            })
          }
        })
      })
    },
    getCashBoxFilters () {
      getCashBoxes().then(response => {
        const cashBoxes = response.data.data
        const payerIndex = this.columnsLabel.map(e => e.field).indexOf('payer')
        cashBoxes.forEach((cashBox) => {
          if (payerIndex > -1) {
            this.columnsLabel[payerIndex].filterTypes.push({
              label: cashBox.name,
              value: cashBox.name
            })
          }
        })
      })
    },
    getCostTypesFilters () {
      getCostTypes().then(response => {
        const costTypes = response.data.data
        const destinationIndex = this.columnsLabel.map(e => e.field).indexOf('destination')
        costTypes.forEach((costType) => {
          if (destinationIndex > -1) {
            this.columnsLabel[destinationIndex].filterTypes.push({
              label: costType.name,
              value: costType.id
            })
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
